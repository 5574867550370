import React, { FC } from 'react';
import { cn } from 'utils';
import { FieldRenderProps } from 'react-final-form';
import { ESpinnerSize } from '@grow-components/Spinners/consts';
import { BasicSpinner } from '@grow-components/Spinners/BasicSpinner';
import { IconWithToolTip, WarningIcon } from '@grow-components/Icon/IconWithTooltip';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@grow-components/Tooltip/Tooltip';
import { Icon } from '@grow-components/Icon/Icon';
import { Input } from '@wmg-ae/legato';

import styles from './text-input.module.scss';
import commonStyles from '../CommonStyles.module.scss';

export enum ETextInputSize {
  large = 'lg',
  small = 'sm'
}

type TTextInputProps = JSX.IntrinsicElements['input'] & {
  label?: string
  labelTooltip?: string
  disabledTooltip?: string
  size?: ETextInputSize
  className?: string
  error?: string
  warningMessages?: ReadonlyArray<string>;
  name: string
  loading?: boolean
  internal?: boolean
  forceError?: boolean
  externalError?: string
}

export const StyledInput = ({ className, ...props }: JSX.IntrinsicElements['input']) => {
  return (
    <input {...props} className={cn(styles.input, className)} />
  );
};

export const TextInput: FC<TTextInputProps> = (props) => {
  const {
    label,
    required,
    placeholder,
    size,
    error,
    warningMessages,
    name,
    loading,
    internal,
    disabledTooltip,
    ...intrinsicProps
  } = props;
  const { t } = useTranslation();

  const renderControl = () => (
    <input
      name={name}
      {...intrinsicProps}
      type="text"
      className={cn('form-control', {
        [`input-${size}`]: !!size
      })} placeholder={placeholder}
    />
  );

  return (
    <div className={cn('form-group', {
      'required': required,
      'has-error': !!error
    }, props.className)}>
      {label && (
        <label className={cn('control-label', commonStyles.inputLabel)}>
          {props.labelTooltip ? (
            <Tooltip text={props.labelTooltip}>{label}</Tooltip>
          ) : (
            <span>{label}</span>
          )}
          {required && <Icon icon="asterisk" className={commonStyles.iconInLabel} />}
          {internal && (
            <IconWithToolTip
              icon={'eye-slash'}
              iconClassname={cn(commonStyles.iconInLabel)}
              text={t('internal_name')}
            />
          )}
          {loading && <BasicSpinner size={ESpinnerSize.SMALL} className={commonStyles.labelSpinner} />}
          {!loading && error && (<WarningIcon text={error} className={commonStyles.iconInLabel} />)}
          {!loading && !error && warningMessages}
        </label>
      )}
      {intrinsicProps.disabled && disabledTooltip ? (
        <Tooltip text={disabledTooltip}>
          {renderControl()}
        </Tooltip>
      ) : (renderControl())}
    </div>
  );
};

export const FFTextInput: FC<FieldRenderProps<string> & TTextInputProps> = (props) => {
  const { input, meta, forceError, loading, inputNumber, ...rest } = props;

  const handleAutoComplete = (e: React.FocusEvent<HTMLInputElement>) => {
    if(inputNumber === 1)
      return e.target.setAttribute('autoComplete', 'off');
    return e.target.setAttribute('autoComplete', 'new-password');
  };
  return (
    <TextInput
      {...input}
      loading={meta.validating || loading}
      autoComplete='off'
      aria-autocomplete='list'
      onFocus={(e)=>handleAutoComplete(e)}
      error={
        forceError
          ? meta.error
          : (meta.touched && meta.error)
      }
      {...rest}
    />
  );
};

export const LegatoFFTextInput: FC<FieldRenderProps<string> & TTextInputProps> = (props) => {
  const { input,
    placeholder,
    autoComplete,
    meta: { touched, error },
    maxLength,
    required,
    disabled,
    label,
    size,
    onChange: customChange,
    onKeyPress,
    onBlur: customBlur,
    loading,
    className,
    isClearable,
    internal,
    forceError,
    disabledTooltip,
    warningMessages = [],
    ...intrinsicProps } = props;
  const { t } = useTranslation();


  const renderControl = () => (
    <Input
      {...input}
      maxLength={maxLength}
      enterKeyHint='search' // when legato input accepts to remove this attribute, please remove it.
      placeholder={placeholder}
      disabled={disabled}
      size={size}
      className={className}
      isClearable={isClearable}
      autoComplete={autoComplete || 'off'}
      aria-autocomplete='list'
      validation={{
        message: (forceError || touched) && !loading && (error || warningMessages?.join('\n')), //validate all errors cases -> when you force an error, when you has warning messages for the field
        type: (forceError || touched) && !loading && (error || warningMessages.length !== 0) && 'error',
        required: required,
        pending: loading
      }}
      onChange={customChange || input.onChange}
      onBlur={customBlur || input.onBlur}
      onKeyPress={onKeyPress}
    />
  );

  return (
    <div className={cn('mb-10', className)}>
      {label && (
        <label className={cn('control-label', commonStyles.inputLabel)}>
          {props.labelTooltip ? (
            <Tooltip text={props.labelTooltip}>{label}</Tooltip>
          ) : (
            <span>{label}</span>
          )}
          {internal && (
            <IconWithToolTip
              icon={'eye-slash'}
              iconClassname={cn(commonStyles.iconInLabel)}
              text={t('internal_name')}
            />
          )}
        </label>
      )}
      {intrinsicProps.disabled && disabledTooltip ? (
        <Tooltip text={disabledTooltip}>
          {renderControl()}
        </Tooltip>
      ) : (renderControl())}
    </div>
  );
};
export const LegatoAutoSuggestInput = (props: any) => {
  const { input, placeholder, autoComplete, className, ...rest } = props;
  return (
    <Input
      {...input}
      placeholder={placeholder}
      className={className}
      enterKeyHint
      autoComplete={autoComplete}
      aria-autocomplete='list'    
      {...rest}
    />
  );
};
