import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { InputsArray } from '@grow-components/Form';
import { ENameType } from 'domains/party/types';
import { CompetenciesItem } from './CompetenciesItem';
import { isAlreadySelected } from '../helpers';
import { ICompetencyProps, TRCompetency } from './types';
import { useCurrentName } from '../useCurrentName';
import { usePermissions } from 'modules/common/hooks/usePersmissions';

export const Competencies: FC<ICompetencyProps> = ({
  namePath,
  dictionary,
  partyType,
  isCompetencyDisabled,
}) => {
  const { t } = useTranslation();
  const { canEditPerfArtistCompetency, canRemovePerfArtistCompetency } = usePermissions();
  const { values: currentName } = useCurrentName(namePath);

  const name = useMemo(() => `${namePath}.competencies`, [namePath]);

  const { placeholder, disabledTooltip } = useMemo(
    () => ({
      placeholder: t('party_placeholders:select_competency'),
      disabledTooltip: t('tooltips:disabled_competency'),
    }),
    [t],
  );

  const isSelected = useCallback(
    (idx: number) => isAlreadySelected(idx, 'competencyId', currentName.competencies),
    [currentName.competencies],
  );

  const competency = useMemo(
    () => {
      if (typeof dictionary === 'undefined') return [];
      const c = dictionary.reduce((a, c) => ({ ...a, [c.partyType]: c.competencies }), {} as TRCompetency);
      return c[partyType] || [];
    },
    [dictionary, partyType],
  );

  const isDisabled = useMemo<boolean | undefined>(
    () => {
      if (currentName.nameId && (!canEditPerfArtistCompetency || !canRemovePerfArtistCompetency)) return true;
      if (currentName.nameType !== ENameType.PKA) {
        if (typeof currentName.isLegal !== 'undefined') return currentName.isLegal;
      }
      return false;
    },
    [currentName, canRemovePerfArtistCompetency, canEditPerfArtistCompetency],
  );

  const item = useCallback<(competencyName: string, idx: number) => JSX.Element>(
    (path, idx) => (
      <CompetenciesItem
        key={path}
        name={path}
        nameValues={currentName}
        placeholder={placeholder}
        disabledTooltip={currentName.isLegal ? disabledTooltip : ''}
        competency={competency}
        required={currentName.nameType === ENameType.PKA}
        isDisabled={isDisabled || isCompetencyDisabled(idx)}
        isAlreadySelected={isSelected(idx)}
        idx={idx}
      />
    ),
  [currentName.nameType, placeholder, disabledTooltip, competency, isSelected, isDisabled],
  );

  return (
    <InputsArray
      title={t('party_labels:competencies')}
      titleTooltip={t('tooltips:competencies')}
      name={name}
      addButtonText={t('party_labels:new_competency')}
      addButtonTooltip={t('tooltips:add_competency_button')}
      deleteButtonTooltip={t('tooltips:delete_competency')}
      component={item}
      disabled={isDisabled}
    />
  );
};
