import set from 'lodash/fp/set';
import compose from 'lodash/fp/compose';
import { Country, EIdentifierType, ERemarkType, Identifier } from 'modules/common/types/partyTypes';
import { TPartyEntryDetail } from 'domains/party/types';
import { createInitialValuesFromExistingParty } from '../forms/CreateEditPartyForm/utils/formValues';
import { TIsniPersonalInfo } from 'domains/isni/types';

interface IState {
  confirmationModal: boolean;
  loading: boolean;
}

export enum EActionType {
  CHECK_AVAILABLE = 'CHECK_AVAILABLE',
  CHECK_SUCCESS = 'CHECK_SUCCESS',
  CHECK_FAILURE = 'CHECK_FAILURE',
  HIDE_MODAL = 'HIDE_MODAL',
}

interface IAction {
  type: EActionType;
}

export function reducer(state: IState, action: IAction) {
  switch(action.type) {
  case EActionType.CHECK_AVAILABLE:
    return set('loading')(true)(state);

  case EActionType.CHECK_SUCCESS:
    return compose(
      set('load')(false),
      set('confirmationModal')(true),
    )(state);

  case EActionType.CHECK_FAILURE:
    return set('loading')(false)(state);

  case EActionType.HIDE_MODAL:
    return set('loading')(false)(state);

  default:
    throw new Error();
  }
}

export function reformatIsni(
  partyName: string | undefined,
  isniValues: {
    ids: Identifier[] | any,
    partyData: TPartyEntryDetail,
    isniComment: string | undefined,
    personalInfo?: TIsniPersonalInfo
  },
  getCountry: (x: string | undefined) => Country | undefined){
  const _mapOfChangeAuthors = isniValues.ids.map((item: Identifier) =>
    (item.identifierType !== 'ISNI')
      && `identifiers[?(@.identifierType=='${item.identifierType}')]`)
    .filter((item: Identifier) => item && item.identifierType !== EIdentifierType.MERGED_ISNI);

  _mapOfChangeAuthors.push('remarks');

  const partyFormatted = createInitialValuesFromExistingParty(isniValues.partyData);

  if (!partyFormatted.countryOfOriginId && isniValues.personalInfo?.nationality) {
    const isniCountry = getCountry(isniValues.personalInfo.nationality);
    _mapOfChangeAuthors.push('party.countryOfOriginId');
    partyFormatted.countryOfOriginId = isniCountry?.countryId;
  }
  const newNames = partyFormatted.names.map((item) => item.nameValue !== partyName ? item :
    {
      ...item,
      identifiers: [...isniValues.ids],
      translations: item.translations && item.translations.length > 0 ? item.translations : [{ languageVariantId: 0, name: '' }],
      remarks: item.remarks && item.remarks.length > 0 ? item.remarks.map(r => r.remarkType !== ERemarkType.ADDITIONAL_INFO ? r : { remarkType: ERemarkType.ADDITIONAL_INFO, text: isniValues.isniComment }) : [{ remarkType: ERemarkType.ADDITIONAL_INFO, text: isniValues.isniComment }],
      _mapOfChangeAuthors
    });
  return { ...partyFormatted, names: newNames };
}

export const initialArg = {
  confirmationModal: false,
  loading: false,
};
