import React, { FC, useEffect, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import Autosuggest from 'react-autosuggest';
import { cn } from 'utils';
import { useUserSearch } from 'domains/search/hooks/useUserSearch';
import { useDebounce } from 'modules/common/hooks/useDebounce';
import styles from '@grow-components/ActivityUsersInput/ActivityUsersInput.module.scss';
import autosuggestStyles from 'modules/common/components/Header/SearchBar/autosuggest.module.scss';
import { Icon } from '@grow-components/Icon/Icon';
import { LegatoAutoSuggestInput } from '@grow-components/Form/TextInput/TextInput';

type TTypeaheadInputProps = {
  name: string
  onSelect: (value: string) => void
  className?: string
  placeholder?: string
  update?: boolean
}

export const FFActivityUsersInput: FC<FieldRenderProps<string> & TTypeaheadInputProps> = (props) => {
  const debouncedValue = useDebounce(props.input.value, 300);
  const [isValueFromList, setIsValueFromList] = useState(false);
  const { loading, users } = useUserSearch(debouncedValue, isValueFromList, props.update);
  const [inputValue, setInputValue] = useState(props.input.value);

  useEffect(() => {
    if(props.input.value === '') {
      setInputValue('');
    }
  },[props.input.value]);

  return (
    <Autosuggest
      inputProps={{
        ...props.input,
        value: inputValue,
        onChange: (event, { newValue, method }) => {
          setIsValueFromList(false);
          if (method === 'type') {
            setInputValue(newValue);
          }
          props.input.onChange(event);
        },
        placeholder: props.placeholder,
        className: cn(props.className),
        onBlur: () => {
          if (!isValueFromList) {
            props.onSelect('');
          }
        }
      }}
      onSuggestionSelected={(event, data) => {
        setIsValueFromList(true);
        setInputValue(data.suggestionValue);
        props.onSelect(data.suggestionValue);
      }}
      getSuggestionValue={suggestion => suggestion}
      onSuggestionsFetchRequested={() => null}
      onSuggestionsClearRequested={() => null}
      containerProps={{ className: styles.container }}
      renderInputComponent={inputProps => (
        <LegatoAutoSuggestInput {...inputProps} value={inputProps.value} suffixIcon={<Icon icon='magnifying-glass' className={cn(styles.icon)}/>}
          validation={{ pending: loading }}
        />
      )}
      renderSuggestion={(suggestion) => <div className={styles.suggestion}>{suggestion}</div>}
      suggestions={users}
      theme={autosuggestStyles}
    />
  );
};
