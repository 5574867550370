import React, { Fragment, useCallback, useMemo } from 'react';
import get from 'lodash/get';
import { cn } from 'utils';
import styles from './cell.module.scss';

import { ESortDirection, IHeaderProps } from '../../types';

export function Cell<K extends string>({
  data,
  actions = {},
  meta = {},
}: IHeaderProps<K>) {

  const makeSort = useCallback(
    (d: ESortDirection) => get(actions, ['onSort'], () => null)(d),
    [actions, data.id],
  );

  const toggleSort = useCallback<() => void>(
    () => {
      if (typeof meta.sortDirection === 'undefined' || meta.sortDirection === ESortDirection.DESC) {
        makeSort(ESortDirection.ASC);
      } else {
        makeSort(ESortDirection.DESC);
      }
    },
  [makeSort, meta],
  );

  const handleSort = useCallback<(d: ESortDirection) => () => void>(
    (d) => () => {
      if (meta?.sortDirection === d) {
        toggleSort();
      } else {
        makeSort(d);
      }
    },
  [meta, data, toggleSort, makeSort],
  );

  const sortButton = useCallback<(direction: ESortDirection) => JSX.Element>(
    (d) => (
      <button
        type="button"
        onClick={handleSort(d)}
        className={cn(styles.button, {
          [styles.desc]: d === ESortDirection.DESC,
          [styles.active]: !(meta.sortDirection === d),
        })}
      />
    ),
  [handleSort, meta],
  );

  const className = useMemo(
    () => cn(
      styles.btnGroup, {
        [styles.active]: typeof meta?.sortDirection !== 'undefined',
      }
    ),
    [meta],
  );

  return (
    <th className={cn(...data.classes)}>
      <div className={styles.col}>
        {
          data.sortable ? (
            <Fragment>
              <button
                className={styles.label}
                type="button"
                onClick={toggleSort}
              >{data.label}</button>
              <div className={className}>
                {
                  sortButton(ESortDirection.ASC)
                }
                {
                  sortButton(ESortDirection.DESC)
                }
              </div>
            </Fragment>
          ) : data.label
        }
      </div>
    </th>
  );
}
