import { useCallback, useState } from 'react';
import { gql, useApolloClient } from '@apollo/client';
import set from 'lodash/fp/set';
import compact from 'lodash/fp/compact';
import { EQueryType, TQueryList, NameSearchAdditionalFields } from './types';

const fields = `
  names {
    nameId
    nameValue
    partyType
    partyId
    partyCountryOfOriginName
    nameType
    nameIsni
  }
`;

export function createAlias(index: number, type: EQueryType) {
  return [type, index].join('_');
}

const creatExactItem = (index: number, nameId?: number) => {
  const type = compact([
    `searchTerm: $term_${index}`,
    'searchType: EXACT_MATCH',
    'countryOfOriginId: $countryOfOriginId',
    'partyType: $partyType',
    typeof nameId === 'number' && `excludeNameId: $exclude_${index}`
  ]).join(', ');

  return `
    ${EQueryType.EXACT}_${index}: searchNames(data: {${type}}) {
      ${fields}
    }
  `;
};

const creatFuzzyItem = (index: number, nameId?: number, partyId?: number) => {
  const type = compact([
    `searchTerm: $term_${index}`,
    'searchType: FUZZY_DUPLICATES',
    'size: 5',
    typeof nameId === 'number' && `excludeNameId: $exclude_${index}`,
    'countryOfOriginId: $countryOfOriginId',
    'partyType: $partyType',
    partyId ? `excludePartyId: ${partyId}` : ''
  ]).join(', ');
  return `
    ${EQueryType.FUZZY}_${index}: searchNames(data: {${type}}) {
      ${fields}
    }
  `;
};


function parseAlias(alias: string): [number, EQueryType] {
  const [type, index] = alias.split('_');
  return [parseInt(index, 10), type as EQueryType];
}

function createItem(q: TQueryList): string {
  const mapper = (t: EQueryType) => t === EQueryType.EXACT ? creatExactItem(q.index, q.excludeNameId) : creatFuzzyItem(q.index, q.excludeNameId, q.excludePartyId);
  return q.types.map(mapper).join(',');
}

function createOneArgument(item: TQueryList): string {
  return compact([
    `$term_${item.index}: String!`,
    typeof item.excludeNameId === 'number' && `$exclude_${item.index}: Int`,
  ]).join(', ');
}

function createArguments(q: ReadonlyArray<TQueryList>) {
  return q.reduce((a, v) => ({
    ...a,
    [`term_${v.index}`]: v.searchTerm,
    [`exclude_${v.index}`]: v.excludeNameId,
  }), {});
}

export function queryBuilder(list: ReadonlyArray<TQueryList>) {

  const terms = list.map(m =>createOneArgument(m)).join(', ');
  const pattern = `
    query duplicateNameExactCheck($countryOfOriginId: Int, $partyType: PARTY_TYPE, ${terms}) {
      ${list.map(createItem).join(',')}
    }
  `;
  return gql`${pattern}`;
}

export function parseResult(data: Record<string, any>) {
  return Object.entries(data).reduce(
    (a, [key, value]) => {
      const [index, type] = parseAlias(key);
      const result = value?.names;
      if (Array.isArray(result)) {
        return set([type, index])(value.names)(a);
      }
      return a;
    },
    {} as Record<EQueryType, any>,
  );
}

export const useCustomQuery = () => {
  const client = useApolloClient();

  const [result, setResult] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState(false);

  const makeQuery = useCallback(
    async (query, variables) => {
      setLoading(true);
      try {
        const { data } = await client.query({ query, variables });
        setResult(data);
      } catch (err) {
        console.warn('errr');
      } finally {
        setLoading(false);
      }
    },
    [client, setResult],
  );

  const validate = useCallback<(l: ReadonlyArray<TQueryList>, a: NameSearchAdditionalFields) => void>(
    (list, additionalFields) => {
      const query = queryBuilder(list);
      const variables = createArguments(list);
      makeQuery(query, { ...variables, ...additionalFields });
    },
  [makeQuery],
  );

  return {
    validate,
    result,
    loading,
  };
};
