import React, { FC, useCallback, useState } from 'react';
import { cn } from 'utils';
import { useEventListener, EEventType } from 'modules/common/hooks/useEventListener';

import styles from './subheader.module.scss';

interface ISubheaderProps {
  location: string | JSX.Element;
  mainInfo?: string | JSX.Element;
  additionalInfo?: string | JSX.Element;
  rightSideBlock?: JSX.Element;
  sticky?: boolean;
  immediateShrink?: boolean;
  noShrink?: boolean;
  className?: string;
  bottom?: JSX.Element;
  mainInfoClassName?: string;
}

export const Subheader: FC<ISubheaderProps> = ({
  location,
  mainInfo,
  additionalInfo,
  rightSideBlock,
  sticky,
  immediateShrink,
  noShrink,
  className,
  bottom,
  mainInfoClassName
}) => {
  const [additionalInfoHidden, setAdditionalInfoHidden] = useState(false);

  const scrollEventListener = useCallback(() => {
    const shrinkBreakpoint = immediateShrink ? 0 : 40;
    const expandBreakpoint = immediateShrink ? 0 : 10;

    setAdditionalInfoHidden((hidden) => {
      if (!hidden && window.scrollY > shrinkBreakpoint) return true;
      if (hidden && window.scrollY < expandBreakpoint) return false;

      return hidden;
    });
  }, []);

  useEventListener(EEventType.scroll, scrollEventListener);

  return <>
    <div className={cn(styles.subheader, {
      [styles.sticky]: sticky,
      [styles.shrink]: sticky && !noShrink && additionalInfoHidden
    }, className)}>
      <div className={styles.infoBlock}>
        <span className={styles.location}>{location}</span>
        <span className={cn(styles.mainInfo, {
          [styles.hiddenPadding]: sticky && !noShrink && additionalInfoHidden
        }, mainInfoClassName)}>{mainInfo}</span>
        <div className={cn(styles.additionalInfo, {
          [styles.hidden]: sticky && !noShrink && additionalInfoHidden
        })}>{additionalInfo}</div>
        {bottom}
      </div>
      {rightSideBlock && <div className={styles.rightSideBlock}>{rightSideBlock}</div>}
      <div className={cn(styles.divider, { [styles.dividerShown]: sticky && additionalInfoHidden && !noShrink })} />
    </div>
  </>;
};
