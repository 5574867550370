import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { Button } from '@wmg-ae/legato';
import { Dialog, EDialogType } from '@grow-components/Popup';
import { EStorageKeys } from 'common/consts';
import { IDS_LABELS } from 'modules/common/helpers/IsniHelpers';
import { EIdentifierType, ENamePrivacyType, Party, Identifier } from 'modules/common/types/partyTypes';
import { BROADCAST_CHANNELS, REQ_TIMEOUT, useBroadcastChannel } from 'modules/common/hooks/useBroadcastChannel';
import Tooltip from '../utils/Tooltip';
import { useAssignIsni } from 'domains/isni/hooks/useAssignISNI';
import { useConfirmation } from 'modules/common/components/ISNI';
import { ToastService } from 'utils/ToastService';

import styles from './isni-assign.module.scss';
import { EIsniPageCameFrom, EIsniPageHashFlag, TIsniQueryParams } from 'modules/common/types/isniTypes';
import { useGetParty, useSaveParty } from 'domains/party/hooks';
import { reformatIsni } from './helpers';
import { useQueryParams } from 'modules/common/hooks/useQueryParams';
import { usePartyLock } from 'domains/party/hooks/usePartyLock';
import { TIsniPersonalInfo } from 'domains/isni/types';
import { useDictionaries } from 'modules/common/hooks/useDictionaries';
import { TButtonContainerStyle } from '@wmg-ae/legato/build/components/Button';
import { EButtonColors, EButtonStyles } from 'modules/common/types/legatoTypes';
import { TLegatoColorType } from '@wmg-ae/legato/build/shared/types';
import { cn } from 'utils';

interface IIsniAssignProps {
  isni?: string;
  ids: Record<EIdentifierType, string>;
  isniComment?: string;
  partyName?: string;
  notAssignable: boolean;
  infoOkText?: string;
  assignFromDetails?: boolean;
  partyId: string;
  personalInfo?: TIsniPersonalInfo;
  buttonColor?: TLegatoColorType;
  buttonContainer?: TButtonContainerStyle
  buttonMessage?: string;
  buttonClassName?: string;
}

export const AssignIsniBtn: FC<IIsniAssignProps> = ({
  isni,
  ids,
  isniComment,
  partyName,
  notAssignable,
  assignFromDetails,
  partyId,
  personalInfo,
  buttonColor,
  buttonContainer,
  buttonMessage,
  buttonClassName
}) => {
  const { t } = useTranslation();
  const {
    search,
    data,
    dialog,
    onInitAssignIsni,
    state,
    hideConfirmModal,
  } = useConfirmation();
  const { sendTMessage } = useBroadcastChannel(BROADCAST_CHANNELS.ISNI);
  const { getCountryByISO } = useDictionaries();
  const { partyData } = useGetParty(+partyId);
  const { savePartyHandler, loading } = useSaveParty(+partyId);
  const { lockParty } = usePartyLock(+partyId);
  const { params } = useQueryParams<TIsniQueryParams>();
  const hash = window.location.hash;
  const connectionInterrupted = useMemo(() => isEmpty(search) && isEmpty(data), [search, data]);

  useEffect(() => {
    const connectionTimeout = setTimeout(() => {
      if (connectionInterrupted) ToastService.warning(t('toasts:isni_interrupted'));

    }, REQ_TIMEOUT);

    return () => clearTimeout(connectionTimeout);
  }, [connectionInterrupted]);

  const nameIsInternal =
    localStorage.getItem(EStorageKeys.namePrivacy) ===
    ENamePrivacyType.INTERNAL_TO_WMG;

  const sendPartyUpdate = async () => {
    if(!partyData) return ;
    try {
      const isniValues = {
        partyData,
        personalInfo,
        ids: Object.entries(ids).map((item) => ({ identifierType: item[0], value: item[1] })) as Identifier[],
        isniComment
      };
      const newValues = reformatIsni(partyName, isniValues, getCountryByISO);
      const response = await savePartyHandler(newValues as Party);
      if(typeof response !== 'undefined'){
        if (hash === '#'+EIsniPageHashFlag.SEARCH_TAB_CLOSED) {
          window.localStorage.removeItem('searchUri');
          window.location.replace(`${window.location.origin}${search}#isniSaved:${partyId}`);
        }
        else {
          window.close();
        }
        // returns the response to validate in the notification process if it should be shown
        return response;
      }
      else{
        ToastService.warning(t('toasts:isni_interrupted'));
      }
    }
    catch(err: unknown) {
      if (err instanceof Error) ToastService.error(t(err.message));
      await lockParty();
      // returns the error to validate if the isni notification should be shown or not
      return err;
    }
  };
  
  const onAssignedIsni = useCallback(() => {
    if (assignFromDetails) sendTMessage.assignedISNI();
    hideConfirmModal();
    if(params.cameFrom === EIsniPageCameFrom.SEARCH) return sendPartyUpdate();
    hideConfirmModal();
    if (hash === '#'+EIsniPageHashFlag.SEARCH_TAB_CLOSED) {
      window.location.replace(`${window.location.origin}${search}#isniSaved:${partyId}`);
    }
    else {
      window.close();
    }
  }, [search, hash, partyData]);
  
  const mapIdentifiers = useCallback(
    () => Object.entries(ids).map(
      ([type, value]) => ({
        identifierType: IDS_LABELS[type].linkKey,
        value
      }),
    ),
    [ids]
  );

  
  const { assignIsni, loading: isAssignIsniLoading } = useAssignIsni(isni as string, mapIdentifiers().filter((itnd: any )=> itnd.identifierType !== EIdentifierType.MERGED_ISNI), isniComment as string, personalInfo, onAssignedIsni);



  return <>
    <div data-tip data-for='isni-privacy-tip'>
      <Button
        label={buttonMessage || t('search:select_isni')}
        containerStyle={buttonContainer || EButtonStyles.outline}
        colorType={buttonColor || EButtonColors.secondaryBlack}
        className={cn(styles.assignBtn, buttonClassName)}
        onClick={onInitAssignIsni}
        disabled={notAssignable || connectionInterrupted || nameIsInternal}
      />
    </div>
    {nameIsInternal &&
      <Tooltip
        id='isni-privacy-tip'
        place="top"
        delayShow={100}
      >
        {t('search:internal_to_wmg')}
      </Tooltip>
    }
    <Dialog
      name={t('confirm_isni')}
      isOpen={state.confirmationModal || loading}
      type={EDialogType.confirm}
      onClose={hideConfirmModal}
      onAction={assignIsni}
      cancelText={t('no')}
      actionText={t('confirm')}
      loading={isAssignIsniLoading || loading}
    >
      <Trans i18nKey="modal:are_you_sure_you_want_to_assign_isni" values={{ name: partyName }} />
    </Dialog>
    {
      dialog
    }
  </>;
};
