import compose from 'lodash/fp/compose';
import update from 'lodash/update';
import set from 'lodash/set';
import flatten from 'lodash/fp/flatten';
import dictionary from './dictionary';
import { EValidationResult, TAST, IASTItem } from './types';

export function checkParenthesis(name: string): boolean {
  return /\(|\)/gm.test(name);
}

const PROHIBITED_WORDS = ['meets', 'presents', 'vs.', ' feat.', 'featuring', 'with'];

function checkProhibitedWords(name: string): boolean {
  for (const word of PROHIBITED_WORDS) {
    if (name.toUpperCase().includes(word.toUpperCase())) {
      return true;
    }
  }
  return false;
}

function isUpperCase(char: string): boolean {
  return char.toUpperCase() === char;
}

function isAllowedSymbol(name: string, i: number): boolean {
  const code = name.charCodeAt(i);
  if (isNaN(code)) return false;
  return /[a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]/gm.test(name.charAt(i));
}

// function isWordsStartWithCapitalLatter(name: string) {
//   return name
//     .split(/\s/)
//     .filter(f => f !== '')
//     .every(f => isUpperCase(f[0]));
// }
//
// export function casing(name: string): boolean {
//   const isAllowed = (i: number) => isAllowedSymbol(name, i);
//   if (!isWordsStartWithCapitalLatter(name)) return true;
//   for (let i = 0; i < name.length; i++) {
//     const current = name.charAt(i);
//     if (isAllowed(i) && isAllowed(i + 1)) {
//       if (isUpperCase(current) && isUpperCase(name.charAt(i + 1))) { // two upper
//         return true;
//       }
//       if (!isUpperCase(current) && isUpperCase(name.charAt(i + 1))) { // lower and upper
//         return true;
//       }
//     }
//   }
//   return false;
// }

export function toAST(name: string): TAST {
  const res: TAST = [];
  let word = 0;
  for (let i = 0; i < name.length; i++) {
    const code = name.charCodeAt(i);
    if (code === 32) {
      word+=1;
    } else {
      update(res, [word, 'term'], c => (c || '') + name.charAt(i));
    }
  }
  return res;
}

function allowedUppercaseWord(item: string): boolean {
  if (item.length > 3) return false;
  return item === item.toUpperCase();
}

function checkExceptionsStandardSymbols(char: string): boolean {
  const exceptions = 'wığüşöçĞÜŞÖÇİ';
  return exceptions.includes(char);
}

function checkCapitalize(item: string): boolean {
  const isAllowed = (i: number) => isAllowedSymbol(item, i);
  let firstAcceptedCar;
  for (let i = 0; i < item.length; i++) {
    const current = item.charAt(i);
    if (checkExceptionsStandardSymbols(current)) return true;
    if (typeof firstAcceptedCar === 'undefined' && isAllowed(i)) {
      firstAcceptedCar = i;
      if (!isUpperCase(current)) { // first allowed char should be in uppercase
        return false;
      }
    }
    if (isAllowed(i) && isAllowed(i + 1)) {
      if (isUpperCase(current) && isUpperCase(item.charAt(i + 1))) { // two upper
        return false;
      }
      if (!isUpperCase(current) && isUpperCase(item.charAt(i + 1))) { // lower and upper
        return false;
      }
    }
  }
  return true;
}

function splitValidation(dict: string[], item: string): boolean {
  for (const pre of dict) {
    if (item.startsWith(pre)) {
      const rest = item.slice(pre.length);
      return checkCapitalize(rest);
    }
  }
  return false;
}

function checkValidWordFactory() {
  const list = flatten(Object.values(dictionary));
  const withApostrof = list.filter(el => /'/.test(el));
  return (item: string) => {
    if (list.includes(item)) return true;
    if (splitValidation(withApostrof, item)) return true;
    return false;
  };
}

function doubleCapitalizer(item: string): boolean {
  const isAllowed = (i: number) => isAllowedSymbol(item, i);
  const capitalChar: number[] = [];
  for (let i = 0; i < item.length; i++) {
    const current = item.charAt(i);
    if (isAllowed(i) && isUpperCase(current)) {
      capitalChar.push(i);
    }
    if (capitalChar.length > 2) return false;
  }
  if (capitalChar.length < 2) return false;
  if (capitalChar.length === 2) {
    const distance = capitalChar[1] - capitalChar[0];
    if (distance > 3) return false;
    if (distance < 2) return false;
  }
  return true;
}

const updateWrapper = <T extends IASTItem>(validator: (el: string) => boolean) => (e: T) => update(e, 'isValid', v => v || validator(e.term));

function taggedUpdateWrapper<T extends IASTItem>(validator: (el: string) => boolean, tag: string) {
  return (e: T) => {
    if (e.isValid === false) {
      const condition = validator(e.term);
      set(e, 'isValid', condition);
      if (condition === true) {
        set(e, tag, true);
      }
    }
    return e;
  };
}

export function validateASTItem(item: IASTItem): IASTItem {
  return compose(
    taggedUpdateWrapper(doubleCapitalizer, 'isDouble'),
    updateWrapper(allowedUppercaseWord),
    taggedUpdateWrapper(checkValidWordFactory(), 'isAllowedWord'),
    updateWrapper(checkCapitalize),
  )(item);
}

export function mapperAST(ast: TAST): TAST {
  return ast.map(validateASTItem);
}

export function validateAST(ast: TAST): boolean {
  return !(
    ast.every(e => e.isValid)
    && !ast.some((e, i) => e.isAllowedWord && i === 0) // allowed word can't be first
    //  && ast.reduce((a, v) => a + Number(v.isDouble || 0), 0) <= 1
  );
}

function checkEntrance<T extends string>(fn: (n: T) => boolean, entrance: EValidationResult) {
  return ([name, res]: [T, ReadonlyArray<EValidationResult>]) => fn(name) ? [name, [...res, entrance]] : [name, res];
}

export function validate(name: string): ReadonlyArray<EValidationResult> {
  return compose(
    checkEntrance(compose(validateAST, mapperAST, toAST), EValidationResult.CASING),
    checkEntrance(checkProhibitedWords, EValidationResult.PROHIBITED_WORDS),
    checkEntrance(checkParenthesis, EValidationResult.PARENTHESIS),
  )([name, []])[1];
}

