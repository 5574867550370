import { Remark } from 'modules/common/types/partyTypes';
import { EIsniPartyType } from 'common/consts';
import { TPartyEntity } from './types';
import { months } from 'moment';

export const getPartyDates = (party: TPartyEntity | undefined) => {
  if (!party) return {};
  const {
    dateOfBeginningDay,
    dateOfBeginningMonth,
    dateOfBeginningYear,
    dateOfEndDay,
    dateOfEndMonth,
    dateOfEndYear
  } = party;

  const filterNotUndefined = (v: number | undefined) => !!v;
  const humanizeRawDate = (rawDateArray: number[]) => rawDateArray
    .map((dateItem, idx) => {
      return (idx === 1 && dateItem) ? months(dateItem - 1) : dateItem;
    })
    .reverse()
    .join(' ');

  const rawStartDate = [dateOfBeginningYear, dateOfBeginningMonth, dateOfBeginningDay].filter(filterNotUndefined) as number[];
  const rawEndDate = [dateOfEndYear, dateOfEndMonth, dateOfEndDay].filter(filterNotUndefined) as number[];
  const startDate: string = humanizeRawDate(rawStartDate);
  const endDate: string = humanizeRawDate(rawEndDate);

  return {
    startDateArray: rawStartDate,
    endDateArray: rawEndDate,
    startDate: startDate,
    endDate: endDate
  };
};

export const nameRemarksSortByDateFn = (aR: Remark, bR: Remark) => {
  if (!aR.lastModifiedAt && !bR.lastModifiedAt) return 0;
  if (!aR.lastModifiedAt) return 1;
  if (!bR.lastModifiedAt) return -1;
  if (aR.lastModifiedAt === bR.lastModifiedAt) return 0;
  return aR.lastModifiedAt > bR.lastModifiedAt ? 1 : -1;
};

export const isniTypeDependantLabels = (type?: string) => {
  const isPerson = type === EIsniPartyType.PERSON;

  return isPerson ? {
    dateStart: 'date_birth',
    dateEnd: 'date_death',
    startPlace: 'born_in'
  } : {
    dateStart: 'date_formed',
    dateEnd: 'date_disbanded',
    startPlace: 'formed_in'
  };
};
