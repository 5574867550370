import React, { FC, useMemo } from 'react';
import Layout from 'modules/common/components/Layout';
import { Redirect, useParams } from 'react-router-dom';
import { useGetISNI } from 'domains/isni/hooks/useGetISNI';
import { Subheader } from '@grow-components/Subheader/Subheader';
import { OverlaySpinner } from '@grow-components/Spinners';
import { formatISNIStatic } from 'modules/common/helpers/formatISNI';
import { IsniCredit } from 'modules/common/components/IsniCredit';
import { ArtistImage } from 'modules/common/components/ArtistImage';
import { PartyFieldsList, TPartyFieldItem } from 'modules/pages/IsniDetailsPage/PartyFieldsList/PartyFieldsList';
import styles from './IsniDetailsPage.module.scss';
import { useTranslation } from 'react-i18next';
import { isniTypeDependantLabels } from 'domains/party/utils';
import { ExpandableDataTable } from '@grow-components/ExpandableDataTable/ExpandableDataTable';
import { isrcLabels, iswcLabels, namesLabels, relationshipsLabels, releaseLabels } from 'domains/isni/consts';
import { sortNamesByNameAndLang } from 'modules/pages/IsniMoreInfoPage/helpers';
import { mapRelationships } from 'domains/isni/utils';
import { createExternalLinks } from 'modules/common/helpers/createExternalLink';
import { Icon } from '@grow-components/Icon/Icon';
import { useSettings } from 'domains/env';
import { changeDateDashToSlash } from 'modules/common/components/PartyHistory/helpers';
import { IdentifierDrawer, TIdentifierItem } from 'modules/common/components/IdentifierDrawer';
import { usePartyImage } from 'domains/party/hooks/usePartyImage';
type TIsniDetailsPageParams = {
  isni: string
}

export const IsniDetailsPage: FC = () => {
  const { t } = useTranslation();
  const { t: tParty } = useTranslation('party_labels');
  const { isni } = useParams<TIsniDetailsPageParams>();
  const { loading, data: isniData } = useGetISNI(isni);
  const { settings } = useSettings();
  const labels = useMemo(() => isniTypeDependantLabels(isniData?.type), [isniData]);
  const { dataImages } = usePartyImage({ isni });
  const identifiersToRender: TIdentifierItem[] = useMemo(() => {
    if (!isniData) return [];
    const identifiers: TIdentifierItem[] = [];
    for (const [id_key, v] of Object.entries(isniData.identifiers)) {
      const { label, link } = createExternalLinks(id_key, v);
      identifiers.push({ title: label, value: v, link: link || '' });
    }
    identifiers.push({ title: t('comments'), value: isniData.comment as string });
    return identifiers.filter(item => !!item.value && item.title !== 'ISNI' && item.title !== 'Comments');
  }, [isniData, settings]);

  const partyItems: TPartyFieldItem[] = [
    { title: tParty('party_type'), value: isniData?.type },
    { title: tParty(labels.dateStart), value: changeDateDashToSlash(isniData?.birthDate, settings?.dateFormat) },
    { title: tParty(labels.dateEnd), value: changeDateDashToSlash(isniData?.deathDate, settings?.dateFormat) },
    { title: tParty('origin_country'), value: isniData?.countryOfOrigin },
    { title: 'ISNI', value: isni },
    { title: 'Comments', value: isniData?.comment }
  ];

  const renderTables = useMemo(() => {
    if (!isniData) return null;

    const { nameVariants, isrcContributions, iswcContributions, relationships, releaseContributions } = isniData;

    return (
      <>
        {nameVariants.length > 0 && (
          <ExpandableDataTable
            title="Names"
            titleTooltip={t('tooltips:isni_detail_page_names')}
            labels={namesLabels}
            data={sortNamesByNameAndLang(nameVariants)} />
        )}
        {releaseContributions.length > 0 &&
          <ExpandableDataTable
            title="Releases"
            titleTooltip={t('tooltips:releases_isni_detail_page')}
            labels={releaseLabels}
            data={releaseContributions} />}
        {isrcContributions.length > 0 && (
          <ExpandableDataTable
            title="Recordings"
            titleTooltip={t('tooltips:sound_recording_information')}
            labels={isrcLabels}
            data={isrcContributions} />
        )}
        {iswcContributions.length > 0 && (
          <ExpandableDataTable
            title="Works"
            titleTooltip={t('tooltips:works_information')}
            labels={iswcLabels}
            data={iswcContributions} />
        )}
        {relationships.length > 0 && (
          <ExpandableDataTable
            title="Relationships"
            titleTooltip={t('tooltips:relationship')}
            labels={relationshipsLabels}
            data={mapRelationships(relationships)} />
        )}
      </>
    );
  }, [isniData, settings]);
  if (loading) return <OverlaySpinner />;

  return isniData ? (
    <Layout>
      <div className={styles.banner}>
        <Icon icon="eye"/>
        <span>Read Only Mode</span>
      </div>
      <div className="container">
        <Subheader
          sticky
          location={'Additional Details'}
          mainInfo={isniData.name}
          additionalInfo={<div>ISNI  <span className={styles.isniValue}>{formatISNIStatic(isniData.isni)}</span></div>}
          rightSideBlock={<IsniCredit bold />}
        />
        <div className={styles.headerContainer}>
          <ArtistImage
            images={dataImages}
            artistName={isniData.name}
            isCentred
            isRound
            expandable
            className={styles.artistAvatar}
          />
          <PartyFieldsList items={partyItems} />
        </div>
        <IdentifierDrawer items={identifiersToRender}/>
        {renderTables}
      </div>
    </Layout>
  ) : (
    <Redirect to={'/not-found'} />
  );
};
