import { useMutation } from '@apollo/client';
import { REASSIGN_IDS } from 'graphql/mutations';
import { BROADCAST_CHANNELS, useBroadcastChannel } from 'modules/common/hooks/useBroadcastChannel';
import { useQueryParams } from 'modules/common/hooks/useQueryParams';
import { EIsniPageCameFrom, TIsniQueryParams, TIsniSearchParams } from 'modules/common/types/isniTypes';
import { ERemarkType } from 'modules/common/types/partyTypes';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

export const useAssignIsni = (isni: string, ids: any, isniComment: string, isniPersonalInfo: any, callback: ()=>void) => {
  const { nameId } = useParams<TIsniSearchParams>();
  const { params } = useQueryParams<TIsniQueryParams>();
  const cameFrom = params.cameFrom;
  const { sendMessage: sendIsniChange } = useBroadcastChannel(BROADCAST_CHANNELS.ISNI_ASSIGN);
  
  const [reassignIds, { loading }] = useMutation(REASSIGN_IDS, {
    variables: {
      data: {
        nameId,
        identifiers: ids,
        comment: isniComment ?? '',
      },
    },
  });

  const asyncCallback = async () => callback();



  const assignIsni = useCallback(() => {
    if (cameFrom === EIsniPageCameFrom.FORM) {
      sendIsniChange({
        nameId,
        identifiers: ids,
        isniRemark: isniComment ? { text: isniComment, remarkType: ERemarkType.ADDITIONAL_INFO }: null,
        personalInfo: isniPersonalInfo || null
      });
      callback();
    }
    if (cameFrom === EIsniPageCameFrom.SEARCH) {
      asyncCallback().then((e: unknown) => {
        if (e instanceof Error) return;
        return sendIsniChange({
          nameId,
          selectedIsni: isni
        });
      });
    }
    if (cameFrom === EIsniPageCameFrom.DETAILS) {
      sendIsniChange({
        nameId,
        identifiers: ids,
        isniRemark: isniComment ? { text: isniComment, remarkType: ERemarkType.ADDITIONAL_INFO }: null,
      });
      reassignIds()
        .then(callback)
        .catch(err => console.warn(err));
    }
  }, [callback, ids, isni]);

  return { assignIsni, loading };
};