import React from 'react';
import { createExternalLinks } from 'modules/common/helpers/createExternalLink';
import { Identifier } from 'modules/common/types/partyTypes';
import { IdentifierItem } from './IdentifierItem';
import { SectionHeader } from '@grow-components/Form/InputsArray/SectionHeader';
import { useTranslation } from 'react-i18next';
import styles from './IdentifiersInfo.module.scss';
import { Tooltip } from '@grow-components/Tooltip/Tooltip';

interface Props {
  identifiers: Identifier[] | undefined;
}

export const IdentifiersInfo: React.FC<Props> = ({ identifiers = [] }) => {
  const { t } = useTranslation('party_labels');

  return (
    <div>
      <SectionHeader
        title={
          <Tooltip text={t('tooltips:additional_ids')}>{t('additional_ids')}</Tooltip>
        }
        className={styles.headerBlock}
      />
      <div className="columns-4">
        {identifiers?.length
          ? (
            identifiers.map((id) => {
              const { link, label } = createExternalLinks(id.identifierType, id.value);
              return label.length > 0 && (
                <div key={id.value}>
                  <IdentifierItem
                    value={id.value}
                    link={link}
                    label={label}
                    clipboardMark={t('toasts:is_copied', { label })}
                  />
                </div>
              );
            })
          ) : (
            <div className="no-data-available">{t('no_ids')}</div>
          )}
      </div>
    </div>
  );
};
