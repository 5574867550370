import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Field, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, EButtonSizes } from '@grow-components/Button';
import { LegatoFFTextInput } from '@grow-components/Form';
import { useConfirmation, EDialogType } from '@grow-components/Popup';
import { TPartyName, ENameType } from 'domains/party/types';
import { ENamePrivacyType } from 'modules/common/types/partyTypes';
import { useSearchQuery } from 'modules/common/components/Search';
import { useWarning } from './Warning';
import { NameDuplicatesTable } from '../NameDuplicatesTable';
import { ExactMatchResult } from './ExactMatchResult';
import { EHiddenNameFields } from './constants';
import { INameFieldProps, THiddenNameFields } from './types';
import { isPerformingArtist as checkForPerformingArtist, hasUUID } from '../../helpers';

type TVariousConst = {
  currentNameValues: TPartyName & THiddenNameFields;
  initialNmaeValues: TPartyName;
  namePath: string;
}

export const NameField: FC<INameFieldProps> = ({
  exactMatchResult,
  fuzzyMatchResults,
  nameIndex,
  disabled,
  isNameValidating,
  isExpanded,
  isRepeated,
  onNameChanged,
}) => {

  const form = useForm();
  const { values, initialValues } = form.getState();

  const { t } = useTranslation();
  const { getURL } = useSearchQuery();

  const [nameModified, setNameModified] = useState(false);
  const isModified = useRef<boolean>(false);
  const { namePath, currentNameValues, initialNmaeValues } = useMemo<TVariousConst>(() => ({
    namePath: `names[${nameIndex}]`,
    currentNameValues: values.names[nameIndex],
    initialNmaeValues: initialValues.names[nameIndex],
  }), [nameIndex, values]);

  const { searchLink, nameValuePath, isPerformingArtist } = useMemo(() => ({
    searchLink: getURL({ searchTerm: currentNameValues.nameValue }),
    nameValuePath: `${namePath}.nameValue`,
    isPerformingArtist: checkForPerformingArtist(currentNameValues),
  }), [currentNameValues, namePath]);

  const paRef = useRef<boolean>(isPerformingArtist);
  const nameTypeRef = useRef(currentNameValues.nameType);

  const isExactResultShow = useMemo<boolean>(
    () => {
      if (currentNameValues._isExactMatchAreIgnored) return false;
      return typeof exactMatchResult !== 'undefined' && exactMatchResult.length !== 0;
    },
    [currentNameValues, exactMatchResult],
  );

  const isFuzzyResultShow = useMemo<boolean>(
    () => {
      if (currentNameValues._fuzzyMatchesAreIgnored) return false;
      if(isExactResultShow) return false;
      return !!fuzzyMatchResults?.length;
    },
    [currentNameValues, isExactResultShow, fuzzyMatchResults],
  );

  const { warningTypes } = useWarning({
    nameIndex,
    searchResultShown: isFuzzyResultShow || isExactResultShow,
    isNameValidating,
    nameModified,
  });

  const cleanValidationFlag = useCallback(
    () => form.batch(() => {
      form.change(`${namePath}._fuzzyMatchesAreIgnored`, false);
      form.change(`${namePath}._isExactMatchAreIgnored`, false);
    }),
    [form],
  );

  const makeSearch = useCallback(
    (fromCompetency?: boolean) => {
      onNameChanged(currentNameValues.nameValue, nameIndex, fromCompetency || false);
      setNameModified(false);
      isModified.current = false;
    },
    [form, namePath, onNameChanged, currentNameValues.nameValue, nameIndex, setNameModified],
  );

  const changeHandler = useCallback<(e: ChangeEvent<HTMLInputElement>) => void>(
    (e) => {
      form.change(nameValuePath, e.target.value);
      setNameModified(true);
      isModified.current = true;
    },
  [form, setNameModified, namePath],
  );

  const handleSearch = useCallback(
    () => {
      if (isModified.current) {
        cleanValidationFlag();
        makeSearch();
      }
    },
    [nameModified, makeSearch, cleanValidationFlag],
  );

  const [proxySearch, dialog] = useConfirmation(
    handleSearch,
    () => {
      if (isModified.current && typeof values.id !== 'undefined' && initialNmaeValues.nameValue !== currentNameValues.nameValue) {
        if (hasUUID(currentNameValues) || checkForPerformingArtist(currentNameValues)) {
          return true;
        }
      }
      handleSearch();
      return false;
    },
    {
      name: t('modal:name_change'),
      message: t('modal:changing_a_name_will_require_research'),
      type: EDialogType.confirm,
      onCancel: (cb: () => void) => {
        form.change(`${namePath}.nameValue`, initialNmaeValues.nameValue);        
        cb();
      },
    },
  );

  const blurHandler = useCallback(
    () => {
      form.blur(nameValuePath);
      proxySearch(null);
    },
    [proxySearch, nameModified],
  );

  const clickHandler = useCallback(
    () => {
      form.batch(() => {
        form.change(`${namePath}._fuzzyMatchesWereIgnoredOnce`, true);
        form.change(`${namePath}._fuzzyMatchesAreIgnored`, true);
      });
    },
    [form, namePath],
  );

  const handleKeyPress = useCallback(
    (event) => {
      if (event.charCode === 13) {
        proxySearch(null);
      }
    },
    [proxySearch],
  );


  useEffect(() => {
    if (nameTypeRef.current !== currentNameValues.nameType) {
      cleanValidationFlag();
      makeSearch();
      nameTypeRef.current = currentNameValues.nameType;
    }
  }, [currentNameValues.nameType, makeSearch, cleanValidationFlag]);

  useEffect(() => {
    if (paRef.current !== isPerformingArtist && currentNameValues.nameType !== ENameType.PKA) {
      paRef.current = isPerformingArtist;
      cleanValidationFlag();
      if (!isPerformingArtist) {
        form.batch(() => {
          form.change(`${namePath}.${EHiddenNameFields.HAS_EXACT_MATCH}`, false);
          setTimeout(() => {form.resetFieldState(nameValuePath);}, 10);
        });
      }
      makeSearch(true);
    }
  }, [isPerformingArtist, makeSearch, form, cleanValidationFlag]);

  useEffect(() => {
    form.change(`${namePath}._isFuzzyMatchesShown`, isFuzzyResultShow);
  }, [isFuzzyResultShow, form]);

  useEffect(() => {
    form.change(`${namePath}._hasExactMatch`, isExactResultShow);
  }, [isExactResultShow, form]);

  // used for opening name on first search when no match on both
  useEffect(
    () => {
      if (fuzzyMatchResults?.length === 0 && (exactMatchResult === undefined || exactMatchResult?.length === 0)) {
        form.change(`${namePath}._fuzzyMatchesWereIgnoredOnce`, true);
      }
    },
    [fuzzyMatchResults, exactMatchResult, form],
  );

  return (
    <>
      <Field
        label={t('party_labels:name')}
        required={true}
        size={EButtonSizes.large}
        component={LegatoFFTextInput}
        warningMessages={warningTypes.map(w => t(`tooltips:warning_${w}`))}
        name={nameValuePath}
        placeholder={t('party_placeholders:enter_name')}
        autoComplete='new-password'
        loading={isNameValidating}
        disabled={disabled}
        internal={!isExpanded && currentNameValues.privacyType === ENamePrivacyType.INTERNAL_TO_WMG}
        onChange={changeHandler}
        onBlur={blurHandler}
        onKeyPress={handleKeyPress}
        inputNumber={values.names.length}
        forceError={isExactResultShow || isFuzzyResultShow || isRepeated !== -1}
      />
      <ExactMatchResult
        data={exactMatchResult || []}
        namePath={namePath}
        isShow={isExactResultShow}
      />
      {
        isRepeated === nameIndex && <div>
          <p>{t('party_labels:party_duplicate_warning')}</p>
        </div>
      }
      {
        isFuzzyResultShow ? (
          <div className="mb-20">
            <p>{t('party_labels:db_duplication_warning')}</p>
            <NameDuplicatesTable data={fuzzyMatchResults || []} />
            <div className="flex justify-end align-center">
              <Link
                to={searchLink}
                target="_blank"
                rel="noopener noreferrer"
                className="mr-35"
              >{t('search:see_more_results')}</Link>
              <Button
                text={t('party_labels:ignore_results')}
                onClick={clickHandler}
              />
            </div>
          </div>
        ) : null
      }
      {
        dialog
      }
    </>
  );
};
