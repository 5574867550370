import React, { useCallback } from 'react';
import { convertDateAllNumbers } from 'modules/common/helpers/dateHelpers';
import { ESpinnerSize } from '@grow-components/Spinners/consts';
import { BasicSpinner } from '@grow-components/Spinners/BasicSpinner';
import { EPartyType, Identifier } from 'modules/common/types/partyTypes';
import { ENameType } from 'domains/party/types';
import { tMessage } from 'modules/common/helpers/translation';
import { ArtistImage } from 'modules/common/components/ArtistImage';
import { useGetParty } from 'domains/party/hooks';
import { useDictionaries } from 'modules/common/hooks/useDictionaries';

import styles from './result-details.module.scss';
import { useSettings } from 'domains/env';
import { createExternalLinks } from 'modules/common/helpers/createExternalLink';
import { Icon } from '@grow-components/Icon/Icon';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@grow-components/Tooltip';
import { ToastService } from 'utils/ToastService';
import { copyToClipboard } from 'modules/common/helpers/copyToClipboard';
import { usePartyImage } from 'domains/party/hooks/usePartyImage';

interface Props {
  partyType: EPartyType;
  partyId: number;
  nameId: number;
}

type Link = {
  identifierLabel: string;
  value: string;
  name: string;
}

const ResultDetails: React.FC<Props> = ({ partyType, partyId, nameId }) => {
  const { isPartyLoading, partyData } = useGetParty(partyId);
  const { dataImages } = usePartyImage({ partyId });
  const { dictionaries } = useDictionaries();
  const { t } = useTranslation();
  const { settings } = useSettings();

  const detailsData = partyData;


  const handleCopy = useCallback(
    (value, label) => {
      copyToClipboard(value);
      ToastService.info(t('toasts:is_copied', { label }));
    },
    [detailsData],
  );

  const formatDetailsData = () => {
    const name = detailsData?.names?.find((name: { nameId?: number }) => name.nameId === nameId);

    const beginningDate = () => convertDateAllNumbers(
      settings?.dateFormat,
      detailsData?.party?.dateOfBeginningYear,
      detailsData?.party?.dateOfBeginningMonth,
      detailsData?.party?.dateOfBeginningDay
    );

    const detailsArr = [];
    if (detailsData?.party?.partyType === EPartyType.PERSON) {
      detailsArr.push(['Person Type', tMessage(detailsData.party.partySubType)]);
      if (detailsData?.party?.dateOfBeginningDay ||
        detailsData?.party?.dateOfBeginningMonth ||
        detailsData?.party.dateOfBeginningYear) {
        detailsArr.push(['Date of Birth', beginningDate()]);
      }
    } else if (detailsData?.party?.partySubType) {
      detailsArr.push(['Group Type', tMessage(detailsData.party.partySubType)]);
      if (detailsData?.party?.dateOfBeginningDay ||
        detailsData?.party?.dateOfBeginningMonth ||
        detailsData?.party.dateOfBeginningYear) {
        detailsArr.push(['Formed Date', beginningDate()]);
      }
    }

    if (name?.majorGenreId as string && dictionaries?.majorGenres) {
      const genre = dictionaries.majorGenres
        .find((genre) => (genre.majorGenreId.toString() === name?.majorGenreId?.toString()))?.name || '';
      detailsArr.push(['Major Genre', genre]);
    }
    if (name?.competencies && name.competencies.length > 0) {
      detailsArr.push([name.competencies.length > 1 ? 'Competencies' : 'Competency',
        name.competencies.map((c) => (
          dictionaries?.competencies
            .find((compType) => compType.partyType.toLowerCase() === partyType.toLowerCase())?.competencies
            .find((compType) => compType.id === c.competencyId)?.value)
        )
          .map(e => e && tMessage(e))
          .join(', ')]);
    }
    const PKA: string[] = [];
    const AKA: string[] = [];
    const FKA: string[] = [];
    detailsData?.names?.forEach((name: { nameType: ENameType; nameValue: string; isLegal?: boolean }) => {
      if (name.isLegal && !name.nameValue.includes('isLegal')) name.nameValue += ('isLegal');
      if (name.nameType === ENameType.PKA) PKA.push(name.nameValue);
      if (name.nameType === ENameType.AKA) AKA.push(name.nameValue);
      if (name.nameType === ENameType.FKA) FKA.push(name.nameValue);
    });
    if (PKA.length > 0) detailsArr.push([ENameType.PKA, PKA.join(', ')]);
    if (AKA.length > 0) detailsArr.push([ENameType.AKA, AKA.join(', ')]);
    if (FKA.length > 0) detailsArr.push([ENameType.FKA, FKA.join(', ')]);
    const labels: string[] = detailsArr.map(item => item[0]);
    const values: (string)[] = detailsArr.map(item => item[1]);
    const separatorIndex = labels.findIndex((el) => el === ENameType.PKA);

    const identifierLabels: Link[] = [];
    
    name?.identifiers?.forEach((item: Identifier) => {
      if(item.identifierType === 'MERGED_ISNI') return; 
      identifierLabels.push({ name: item.value, identifierLabel: item.identifierType.replace('_', ' '), value: item?.value ? item.value : '' });
    });

    const isniDisambiguation = name?.remarks?.find(item => item.remarkType === 'ADDITIONAL_INFO');

    return { labels, values, separatorIndex, identifierLabels, isniDisambiguation };
  };

  const { labels, values, separatorIndex, identifierLabels, isniDisambiguation } = formatDetailsData();

  const formatColumn = useCallback(
    (column: string[], sepIndex: number) =>
      column.map((el, i) =>{
        if(el.includes('isLegal')){
          const a = el.split('isLegal');
          return <p key={el} className={'result-item-detail-line ' + (i === sepIndex ? 'separator' : '')}>
            {a[0]} <text className='text'>( <Icon icon='check-circle' /> Legal Name )</text>{a[1]}
          </p>;
        }
        return <p key={el} className={'result-item-detail-line ' + (i === sepIndex ? 'separator' : '')}>{el}</p>;
      }),
    [],
  );

  const formatWithTooltip = useCallback(
    (column: Link[], sepIndex: number) =>
      column.map(({ identifierLabel, value, name }, i) =>{
        let columnLabel = <></>;
        if(value === '') columnLabel = <p className='identifiers-labels'>{identifierLabel}</p>;
        const { link, label } = createExternalLinks(identifierLabel, value);
        link === null ? columnLabel = <p className='identifiers-labels'>{identifierLabel}</p> :
          columnLabel = <p className='identifiers-labels'><a href={link} target="_blank" rel="noreferrer noopener">{label}</a></p>;

        return (
          <div key={value} className='identifiers-column'>
            {columnLabel}
            <p className={' ' + (i === sepIndex ? 'separator' : '')}>{name}</p>
            <button className='identifiers-copy-button'
              onClick={()=>handleCopy(name, identifierLabel)}>
              <Icon icon="copy" />
              <Tooltip text={t('common:click_to_copy')} className={styles.identifier__buttonWrapper} />
            </button>
          </div>

        );
      }),
    [],
  );

  return (
    <tr className="result-item-details">
      <td colSpan={12}>
        <div className="row-flex">
          <div className={styles.thumbnail}>
            <ArtistImage
              className={styles.partyImage}
              images={dataImages}
              isCentred
              expandable
            />
          </div>
          <div className="col-md-1 result-item-detail-labels">
            {!(isPartyLoading) ? formatColumn(labels, separatorIndex) : <BasicSpinner size={ESpinnerSize.SMALL} />}</div>
          <div className="col-md-2 result-item-detail-values">{!(isPartyLoading) && formatColumn(values, separatorIndex)}</div>
          <div className="col-md-4 result-item-detail-labels">{!(isPartyLoading) && formatWithTooltip(identifierLabels, -1)}</div>
          <div  className="col-md-2 result-item-comment-section">
            <p key={isniDisambiguation?.remarkId} className={'result-item-detail-line '}>{isniDisambiguation?.remarkType?.replace(/_/g,' ')}</p>
            <p key={isniDisambiguation?.lastModifiedBy} className={'result-item-comment-values '}>{isniDisambiguation?.text}</p>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default ResultDetails;
