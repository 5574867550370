import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import { moment } from 'utils';
import { Tooltip } from '@grow-components/Tooltip';
import { RecordChangeTimeAndAuthor } from '@grow-components/RecordChangeTimeAndAuthor/RecordChangeTimeAndAuthor';
import { Button, EButtonVariants } from '@grow-components/Button';
import { Button as LegatoButton } from '@wmg-ae/legato';
import { EButtonStyles } from 'modules/common/types/legatoTypes';
import { ViewField } from '@grow-components/ViewField/ViewField';
import { Icon } from '@grow-components/Icon/Icon';
import { RemarkCard } from '@grow-components/Remarks/RemarkСard';
import { ESpinnerSize } from '@grow-components/Spinners/consts';
import { BasicSpinner } from '@grow-components/Spinners/BasicSpinner';
import { SEARCH_ISNI } from 'graphql/queries';
import { EPartyHistoryEntity } from 'domains/history';
import { ENameType, TPartyName } from 'domains/party/types';
import { EIdentifierType, ENamePrivacyType, EPartyType, Identifier } from 'modules/common/types/partyTypes';
import { createLinkISNI, formatISNIStatic } from 'modules/common/helpers/formatISNI';
import { tMessage } from 'modules/common/helpers/translation';
import { useDictionaries } from 'modules/common/hooks/useDictionaries';
import { EField, HistoryTooltip, THistoryGroup, useHistoryElement } from './HistoryTooltip';
import { IdentifierItem, IdentifiersInfo } from './Identifier';
import { EIsniSearchParam, IIsniSearchFilter } from 'modules/common/types/isniTypes';
import { useQueryParams } from 'modules/common/hooks/useQueryParams';

// TODO: cleanup styles namings
import styles from 'modules/common/components/forms/CreateEditPartyForm/components/formModules/NameModule.module.scss';
import { BROADCAST_CHANNELS, useBroadcastChannel } from 'modules/common/hooks/useBroadcastChannel';
import { useSettings } from 'domains/env';

interface INameItemProps {
  name: TPartyName;
  idx: number;
  partyType: EPartyType;
  pkaNameValue: string;
  historyGroupByEntity?: THistoryGroup;
}

export const NameItem: FC<INameItemProps> = ({
  name,
  idx,
  historyGroupByEntity,
  partyType,
  pkaNameValue
}) => {

  const { t } = useTranslation('party_labels');
  const { createURL } = useQueryParams();
  const { dictionaries } = useDictionaries();
  const { label: historyLabel } = useHistoryElement(historyGroupByEntity);
  const [searchIsni, { data, loading }] = useLazyQuery<any, IIsniSearchFilter>(SEARCH_ISNI);
  const isniInfo = useMemo(() => data ? data.searchIsni.isni[0] : undefined, [data]);
  const { settings } = useSettings();
  const [currentIds, setCurrentIds] = useState(name.identifiers ?? []);
  const { channel } = useBroadcastChannel(BROADCAST_CHANNELS.ISNI_ASSIGN);

  const messageHandler = useCallback(
    (message) => {
      const { nameId: _nameId, identifiers: newIdentifiers } = message;

      if (name.nameId === +_nameId) {
        const unchangedIds = currentIds.filter(({ identifierType }) =>
          (newIdentifiers.findIndex((id: Identifier) => id.identifierType === identifierType) < 0));
        unchangedIds.forEach(({ value, identifierType }) => newIdentifiers.push({ value, identifierType }));
        setCurrentIds(newIdentifiers);
      }
    }, [currentIds],
  );

  useEffect(() => {
    channel.addEventListener('message', messageHandler);
    return () => {
      channel.removeEventListener('message', messageHandler);
    };
  }, [messageHandler]);

  const isni = currentIds.find(f => f.identifierType === EIdentifierType.ISNI)?.value;
  const uaid = currentIds.find(f => f.identifierType === EIdentifierType.UAID)?.value;
  const majorGenre = dictionaries?.majorGenres.find(g => g.majorGenreId === name.majorGenreId);
  const minorGenre = majorGenre?.minorGenres.find((genre) => genre.minorGenreId === name.minorGenreId);

  useEffect(() => {
    if (isni) {
      searchIsni({
        variables: {
          data: {
            identifier: {
              isFull: false,
              value: isni.replace(/\s+/g, ''),
              type: EIsniSearchParam.ISNI,
            }
          }
        }
      });
    }
  }, [isni]);

  const additionalIdsList = useCallback<(idf?: Identifier[]) => Identifier[]>(
    identifiers => {
      if (typeof identifiers === 'undefined') return [];
      const filteredIdentifiers = identifiers.filter(f => f.identifierType !== EIdentifierType.ISNI && f.identifierType !== EIdentifierType.UAID && f.identifierType !== EIdentifierType.MERGED_ISNI);
      return filteredIdentifiers.sort((a,b) => {
        const key: keyof Identifier = a.identifierType !== b.identifierType ? 'identifierType' : 'value';
        return a[key] > b[key] ? 1 : -1;
      });
    }, [],
  );

  const openDetailsPageInNewTab = useCallback((isni?: string) => () => {
    window.open(`/isni-details/${isni}`);
  }, []);

  const goToGCDMArtistInfo = useCallback(() => {
    const queryParams = {
      pkaName: pkaNameValue,
    };

    uaid && window.open(createURL(queryParams, `/gcdm-more-info/${uaid}`));
  }, [uaid, pkaNameValue]);

  const uaidWithGCDMLink = useMemo(() => {
    if (!uaid) return;

    return (
      <div className="columns-4">
        <IdentifierItem
          label={
            <Tooltip text={t('tooltips:uaid_details_tooltip')} place='right'>
              <Button
                variant={EButtonVariants.link}
                className={styles.uaidLinkBtn}
                icon="arrow-right"
                iconClassname={styles.uaidLinkBtnIcon}
                text="UAID"
                onClick={goToGCDMArtistInfo} />
            </Tooltip>
          }
          clipboardMark={t('toasts:is_copied', { label: 'UAID' })}
          extraLabel={
            <HistoryTooltip
              gkey={EPartyHistoryEntity.IDENTIFIER}
              data={historyGroupByEntity}
              values={{ identifier: EIdentifierType.UAID, nameValue: name.nameValue }}
              settings={settings}
            />
          }
          value={uaid}
        />
      </div>
    );
  }, [uaid, goToGCDMArtistInfo, settings]);

  const isniMoreInfoTooltip = useMemo(() => {
    if (!isniInfo) return null;
    const parsedBirthDate = moment(isniInfo?.birthDate);
    let age = `(${parsedBirthDate.toNow(true)} ago)`;
    if (isniInfo?.deathDate) {
      const parsedDeathDate = moment(isniInfo?.deathDate);
      age = `(aged ${parsedDeathDate.diff(parsedBirthDate, 'years')})`;
    }
    const content = <>
      {(!isniInfo?.birthDate && !isniInfo?.birthPlace && !isniInfo?.deathDate && !isniInfo?.comment)
        ? (
          <div className={styles.isniInfoEmpty}>No Preview Data Available</div>
        ) : (
          <>
            <div className={styles.isniInfoContent}>
              <div className={styles.isniInfoContent__column}>
                {isniInfo?.birthDate && (
                  <div className={styles.isniGeneralInfo__item}>
                    <div className={styles.isniInfoLabel}>{ partyType.toLowerCase() === 'person' ? 'Date of Birth' : 'Date Formed'}</div>
                    <div>{isniInfo.birthDate} {!isniInfo?.deathDate && age}</div>
                  </div>
                )}
                {isniInfo?.birthPlace && (
                  <div className={styles.isniGeneralInfo__item}>
                    <div className={styles.isniInfoLabel}>{partyType.toLowerCase() === 'person' ? 'Born in' : 'Formed in'}</div>
                    <div>{isniInfo.birthPlace}</div>
                  </div>
                )}
                {isniInfo?.deathDate && (
                  <div className={styles.isniGeneralInfo__item}>
                    <div className={styles.isniInfoLabel}>{partyType.toLowerCase() === 'person' ? 'Date of Death' : 'Date Disbanded'}</div>
                    <div>{isniInfo?.deathDate} {isniInfo.type.toLowerCase() === 'person' && age}</div>
                  </div>
                )}
              </div>
              <div className={styles.isniInfoContent__column}>
                {isniInfo?.comment && (
                  <div className={styles.isniGeneralInfo__item}>
                    <div className={styles.isniInfoLabel}>Comments</div>
                    <div>{isniInfo.comment}</div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      <div className={styles.isniInfoFooter}>
        <Button
          variant={EButtonVariants.link}
          icon="angle-right"
          text="View ISNI Details"
          onClick={openDetailsPageInNewTab(isni)} />
      </div>
    </>;

    return <Tooltip
      text={content}
      clickable
      place="right"
      type="light"
      tooltipClassName={styles.isniInfoTooltip}
      delayHide={150}
    >
      <Icon icon="arrow-right" className={styles.isniInfoIcon} />
    </Tooltip>;
  }, [isniInfo]);

  return (
    <div className={styles.nameCard} key={idx}>
      <div className={styles.nameCard__header}>
        <h4 className={styles.nameCard__title}>
          <Tooltip text={t(`tooltips:${name.nameType.toLowerCase()}`)}>
            {t(name.nameType.toLowerCase())}
          </Tooltip>
          {
            name.isLegal ? (
              <Tooltip text={t('tooltips:legal_name_pill')}>
                <span className="legal-name-label badge badge-success">{t('common:legal_name')}</span>
              </Tooltip>
            ) : null
          }
          <RecordChangeTimeAndAuthor
            className={styles.changedTimestamp}
            type="edited"
            createdBy={name.nameCreatedBy}
            createdAt={name.nameCreatedAt}
            editedAt={name.nameUpdatedAt}
            editedBy={name.nameUpdatedBy}
          />
        </h4>
        {loading && (
          <div className={styles.isniSpinnerWrapper}>
            <BasicSpinner size={ESpinnerSize.SMALL} />
          </div>
        )}
        {isniInfo && (
          <Tooltip text={t('tooltips:isni_details_link')}>
            <LegatoButton
              containerStyle={EButtonStyles.link}
              className={styles.isniButton}
              icon="info-circle"
              label="ISNI Details"
              onClick={openDetailsPageInNewTab(isni)}
            />
          </Tooltip>
        )}
      </div>
      <div className=" mt-40">
        <ViewField
          size="lg"
          label={
            <>
              <span>{t('name')}</span>
              {
                name.privacyType === ENamePrivacyType.INTERNAL_TO_WMG ? (
                  <Tooltip className="privacy-indicator" place="right" text={t('tooltips:privacy_indicator')}>
                    <Icon icon={'eye-slash'} />
                  </Tooltip>
                ) : null
              }
              <HistoryTooltip
                gkey={EPartyHistoryEntity.NAME}
                data={historyGroupByEntity}
                field={EField.nameValue}
                values={{ nameType: name.nameType }}
                settings={settings}
              />
            </>
          }
          value={name.nameValue}
        />
      </div>
      <div className="columns-4 mt-40">
        <IdentifierItem
          label={<Tooltip className={name.nameType !== ENameType.PKA ? styles.isniAKA : ''} text={t('tooltips:isni')}>ISNI</Tooltip>}
          clipboardMark={t('toasts:is_copied', { label: 'ISNI' })}
          extraLabel={
            <>
              {
                loading ? <BasicSpinner className={styles.isniInfoSpinner} size={ESpinnerSize.SMALL} /> : isniMoreInfoTooltip
              }
              <HistoryTooltip
                gkey={EPartyHistoryEntity.IDENTIFIER}
                data={historyGroupByEntity}
                values={{ identifier: EIdentifierType.ISNI, nameValue: name.nameValue }}
                settings={settings}
              />
            </>
          }
          link={createLinkISNI(isni)}
          value={formatISNIStatic(isni)}
        />
        {/* UAID block */}
        {uaidWithGCDMLink}
        {/* end UAID block */}
        <ViewField
          label={
            historyLabel({
              label: t('major_genre'),
              tooltip: t('tooltips:major_genre'),
              gkey: EPartyHistoryEntity.NAME,
              field: EField.majorGenre,
              values: { nameType: name.nameType, nameValue: name.nameValue }
            })
          }
          value={majorGenre?.name}
        />
        <ViewField
          label={
            historyLabel({
              label: t('minor_genre'),
              tooltip: t('tooltips:minor_genre'),
              gkey: EPartyHistoryEntity.NAME,
              field: EField.minorGenre,
              values: { nameType: name.nameType, nameValue: name.nameValue }
            })
          }
          value={minorGenre?.name}
        />
      </div>
      <div className="mt-40">
        <IdentifiersInfo identifiers={additionalIdsList(currentIds)} />
      </div>
      {!!name.competencies?.length && (
        <div className="mt-40">
          <div className="flex align-items-center">
            <Tooltip className={styles.additionalTooltip} text={t('tooltips:competencies')}>
              <label>{t('competencies')}</label>
            </Tooltip>
            <HistoryTooltip
              gkey={EPartyHistoryEntity.COMPETENCY}
              data={historyGroupByEntity}
              values={{ nameValue: name.nameValue }}
              settings={settings} />
          </div>
          <div className="columns-4">
            {name.competencies.map((nameCompetency) => {
              const competency = dictionaries?.competencies
                .find((competencyGroup) => competencyGroup.partyType === partyType)?.competencies
                .find((competency) => competency.id === nameCompetency.competencyId)?.value;
              return (
                <div key={nameCompetency.nameCompetencyId}>
                  <p className={styles.competenciesParagraph}>{competency && tMessage(competency)}</p>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {!!name.translations?.length && (
        <div className="mt-40">
          <div className="row">
            <div className="col-xs-6 col-sm-3 flex align-items-center">
              <Tooltip className={styles.additionalTooltip} text={t('tooltips:translations')}>
                <label>{t('translations')}</label>
              </Tooltip>
              <HistoryTooltip
                gkey={EPartyHistoryEntity.TRANSLATION}
                data={historyGroupByEntity}
                values={{ nameValue: name.nameValue }}
                settings={settings} />
            </div>
          </div>
          <div className="row mt-15">
            {name.translations.map(t => {
              const language = dictionaries?.translationLanguages
                .find(l => l.languageVariantId === t.languageVariantId);
              return language && (
                <div className="col-xs-6 col-sm-3" key={t.nameTransId}
                >
                  <ViewField
                    label={language.displayName}
                    value={t.name}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
      {!!name.remarks?.length && (
        <div className="mt-40">
          <div className={styles.remarks}>
            <label>{t('remarks')}</label>
          </div>
          <div className="columns-4 mt-15">
            {name.remarks.map(remark => (
              <RemarkCard
                key={remark.remarkId}
                title={tMessage(remark.remarkType)}
                text={remark.text}
                author={remark.lastModifiedBy}
                format={settings}
                date={remark.lastModifiedAt}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
