import { NameEntityInput } from 'graphql/types';
import { Identifier, Party, PartyCompetency, Remark, Translation } from 'modules/common/types/partyTypes';
import { useMutation } from '@apollo/client';
import { SAVE_PARTY } from '../graphql';
import {  TPartySaveResponse, TPartySaveVars } from './types';
import { formatNameQuotes } from 'modules/common/components/forms/CreateEditPartyForm/components/helpers';

export const useSaveParty = (partyId?: number) => {
  const [
    createParty, { loading: createPartyLoading }
  ] = useMutation<TPartySaveResponse, TPartySaveVars>(SAVE_PARTY);

  const loading = createPartyLoading;
  const savePartyHandler = async (values: Party) => {
    const {
      partyType,
      partySubType,
      countryOfOriginId,
      languageOfOriginId,
      dateOfBeginningYear,
      dateOfBeginningMonth,
      dateOfBeginningDay,
      dateOfEndYear,
      dateOfEndMonth,
      dateOfEndDay,
      names,
      signedByWMG
    } = values;
    const processedNames: NameEntityInput[] = names.map((name: any) => {
      const { nameValue, nameType, isLegal, privacyType } = name;
      const nameObj: NameEntityInput = { nameValue, nameType, isLegal, privacyType };
      if (name.nameId) nameObj.nameId = name.nameId;
      if (name._isExactMatchAreIgnored) nameObj.isExactMatchAreIgnored = name._isExactMatchAreIgnored;
      if (name.majorGenreId) nameObj.majorGenreId = name.majorGenreId;
      if (name.minorGenreId) nameObj.minorGenreId = name.minorGenreId;
      if (name?.translations?.length) {
        nameObj.translations = name.translations
          .filter((t: Translation) => t.languageVariantId && t.name)
          .map((t: Translation) => ({
            nameTransId: t.nameTransId,
            languageVariantId: Number(t.languageVariantId),
            name: t.name,
          }));
      }
      if (name?.identifiers?.length) {
        nameObj.identifiers = name.identifiers
          .filter((identifier: Identifier) => identifier.identifierType && identifier.value)
          .map((identifier: Identifier) => ({
            identifierType: identifier.identifierType,
            value: identifier.value,
          }));
      }
      if (name?.competencies?.length) {
        nameObj.competencies = name.competencies
          .filter((c: PartyCompetency) => c && c.competencyId)
          .map((c: PartyCompetency) => ({
            competencyId: Number(c.competencyId),
            nameCompetencyId: Number(c.nameCompetencyId),
          }));
      }
      if (name?.remarks?.length) {
        nameObj.remarks = name.remarks
          .filter((r: Remark) => r.remarkType && r.text)
          .map((r: Remark) => ({
            remarkId: r.remarkId,
            remarkType: r.remarkType,
            text: r.text,
          }));
      }
      nameObj.remarks = nameObj.remarks || [];
      return nameObj;
    });
    const allMapOfChangeAuthors = names.map(item => item._mapOfChangeAuthors?.map((subItem) => {
      if (subItem.includes('party')) return subItem;
      const nameValue = !item.nameValue.includes('\'') ? item.nameValue : formatNameQuotes(item.nameValue);
      return `names[?(@.nameValue=='${nameValue}')].${subItem}`;
    }) || []).flat();
    const processedQuansicChanges = allMapOfChangeAuthors.map(item => ({ path: item as string, author: 'Quansic' })) || [];

    const party = {
      partyId: Number(partyId),
      partyType,
      partySubType,
      dateOfBeginningYear: Number(dateOfBeginningYear),
      dateOfBeginningMonth: Number(dateOfBeginningMonth),
      dateOfBeginningDay: Number(dateOfBeginningDay),
      dateOfEndYear: Number(dateOfEndYear),
      dateOfEndMonth: Number(dateOfEndMonth),
      dateOfEndDay: Number(dateOfEndDay),
      signed: signedByWMG
    };
    if (countryOfOriginId) Object.assign(party, { countryOfOriginId });
    if (languageOfOriginId) Object.assign(party, { languageOfOriginId });
    try {
      const res = await createParty({
        variables: {
          data: {
            party,
            names: processedNames,
            mapOfChangeAuthors: processedQuansicChanges
          }
        }
      });
      return res.data?.partyCreateUpdate;
    } catch (err: any) {
      throw new Error(err);
    }
  };
  return { savePartyHandler, loading };
};
