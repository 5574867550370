export interface IHeaderData<T extends string> {
  id: T;
  classes: ReadonlyArray<any>;
  label: string | JSX.Element;
  sortable: boolean;
}

export enum ESortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface IHeaderMeta {
  sortDirection?: ESortDirection;
  className?: string;
}

export interface IHeaderProps<K extends string> {
  data: IHeaderData<K>;
  actions?: {
    onSort?(direction: ESortDirection): void;
  },
  meta?: IHeaderMeta
}

export interface ILayoutProps {
  className?: string;
}
