import React, { FC, useCallback, useMemo } from 'react';
import { IHeaderData, ESortDirection, IHeaderMeta, IHeaderProps, ILayoutProps } from './types';
import { CellDefault, LayoutDefault } from './default';

interface IProps<K extends string> {
  fields: ReadonlyArray<IHeaderData<K>>;
  renderItem?: () => FC<IHeaderProps<K>>;
  renderLayout?: () => FC<ILayoutProps>;
  className?: string;
  action: (id: K, direction: string) => void,
  getMeta?: (data: IHeaderData<K>, i: number) => IHeaderMeta | undefined,
}

export function Header<K extends string> ({
  fields,
  renderItem = () => CellDefault,
  renderLayout = () => LayoutDefault,
  className,
  action,
  getMeta,
}: IProps<K>) {

  const Item = useMemo<FC<IHeaderProps<K>>>(() => renderItem(), [renderItem]);

  const Layout = useMemo<FC<ILayoutProps>>(() => renderLayout(), [renderLayout]);

  const getAction = useCallback(
    (d) => ({
      onSort: (direction: ESortDirection) => {
        action(d.id, direction);
      }
    }),
    []
  );

  return (
    <Layout className={className}>
      {
        fields.map((e, i) => (
          <Item
            key={e.id}
            data={e}
            actions={getAction(e)}
            meta={typeof getMeta === 'function' ? getMeta(e, i) : undefined}
          />
        ))
      }
    </Layout>
  );
}
