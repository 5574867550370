import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useForm } from 'react-final-form';
import get from 'lodash/get';
import { EDialogType, useConfirmation, actionCompose } from '@grow-components/Popup';
import { Party } from 'modules/common/types/partyTypes';
import { ENameType } from 'domains/party/types';

type TArgument = { nameIndex: number, checked: boolean };

type TCallBack = (d: TArgument) => void;
type TReturn = [TCallBack, React.ReactNode];

export function useLigalNameConfirmation(): TReturn {
  const { t } = useTranslation();
  const { getState, change, batch } = useForm<Party>();

  const toggleLegal = useCallback(
    ({ nameIndex, checked }) => {
      const { values: { names } } = getState();
      const oldIndex = names.findIndex(n => n.isLegal);
      const isNotPKA = names[nameIndex].nameType !== ENameType.PKA;
      batch(() => {
        change(`names[${nameIndex}].isLegal` as any, checked),
        oldIndex !== nameIndex ? change(`names[${oldIndex}].isLegal` as any, false) : undefined;

        if(checked) {
          isNotPKA && change(`names[${nameIndex}].majorGenreId` as any, '');
          isNotPKA && change(`names[${nameIndex}].minorGenreId` as any, '');
          isNotPKA && change(`names[${nameIndex}].remarks` as any, undefined);
        } else undefined;
      });
    },
    [getState, change, batch],
  );

  const getName = useCallback(
    (idx: number) => {
      const { values: { names } } = getState();
      return get(names, [idx, 'nameValue']) || get(names, [idx, 'nameType']);
    },
    [getState],
  );

  const message = useCallback(
    ({ nameIndex }: TArgument) => {
      const { values: { names } } = getState();
      const oldIndex = names.findIndex(n => n.isLegal);
      return (
        <Trans
          i18nKey="modal:confirm_change_legal_name"
          values={{ prevName: getName(oldIndex), nextName: getName(nameIndex) }}
          components={{ bold: <b/> }}
        />
      );
    },
    [getState, getName],
  );

  const removeMessage = useCallback(
    ({ nameIndex }: TArgument) => {
      const name = getName(nameIndex);
      return (
        <Trans
          i18nKey='modal:remove_legal_name_message'
          values={{ name }}
          defaults='Are you sure you want {{name}} to no longer be the Legal Name for this party?'
        />
      );
    },
    [getState],
  );

  const condition = useCallback(
    ({ checked }: TArgument) => {
      const { values: { names, id } } = getState();
      if (checked && typeof id !== 'undefined' && names.some(n => n.isLegal)) return true;
      return false;
    },
    [getState],
  );

  const removeCondition = useCallback(
    ({ nameIndex, checked }: TArgument) => {
      const { values: { names, id } } = getState();
      const { nameId, isLegal } = names[nameIndex];
      if (!checked && typeof id !== 'undefined' && nameId && isLegal) return true;
      return false;
    },
    [getState],
  );

  const conditionAKA = useCallback(
    ({ nameIndex, checked }: TArgument) => {
      const { values: { names } } = getState();
      const { nameType, majorGenreId, remarks } = names[nameIndex];
      if (nameType !== ENameType.PKA && checked && (majorGenreId || remarks?.find(item => item.text))) return true;
      return false;
    },
    [getState],
  );

  const [proxy1, dialog1] = useConfirmation(
    toggleLegal,
    removeCondition,
    {
      name: t('modal:remove_legal_name'),
      message: removeMessage,
      type: EDialogType.destructive,
      actionText: t('common:confirm'),
    }
  );

  const [proxy2, dialog2] = useConfirmation(
    toggleLegal,
    condition,
    {
      name: t('modal:legal_name_change'),
      message,
      type: EDialogType.warning,
      actionText: t('common:confirm'),
    }
  );

  const [proxy3, dialog3] = useConfirmation(
    toggleLegal,
    conditionAKA,
    {
      name: t('modal:legal_name_change'),
      message: t('modal:name_legal_delete_some_props'),
      type: EDialogType.warning,
      actionText: t('common:confirm'),
    }
  );

  return [
    actionCompose(toggleLegal, proxy1, proxy2, proxy3),
    (<>{dialog1}{dialog2}{dialog3}</>),
  ];
}
